import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { IFeedback, IResult } from './types';

export const feedbackAPI = createApi({
    reducerPath: 'feedback',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: (build) => ({
        postFeedback: build.mutation<IResult, IFeedback>({
            query: (payload) => ({
                url: `${Entity.feedback}`,
                method: 'POST',
                body: payload,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            })
        })
    }),
});