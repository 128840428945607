import styled from 'styled-components/macro';

export const Component = styled.header`
    width: 100%;
    height: var(--header-sz-height);
    padding: 0 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--page-bg-primary);
    color: var(--text-color-primary);
    z-index: 4;
`;

export const Logo = styled.div`
    height: var(--header-sz-height);
    cursor: pointer;

    h1 {
        margin-left: 10px;
        color: var( --text-color-primary);
        font-size: var(--text-sz-subhead);
        font-family: var(--font-normal);
        text-transform: capitalize;
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    
    h1 {
        color: var(--text-color-primary);
        font-size: var(--text-sz-base);
        font-family: var(--font-bold);
        text-transform: uppercase;
    }
`;

export const Title = styled.h1`
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
    font-family: var(--font-bold);
    font-size: var(--text-sz-base);
    text-transform: uppercase;
`;

export const Menu = styled.div<{ isActiveMenu:boolean }>`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    position: relative;

    button {
        height: 32px;
        padding: 8px;
        position: absolute;
        right: -8px;
        background: none;
        border: none;
        cursor: pointer;

        svg {
            width: var(--icon-sz-width);
            height: var(--icon-sz-height);

            path {
                fill: ${({ isActiveMenu }) =>
                    isActiveMenu ? 'var(--brand-color-1)' : 'var(--text-color-secondary)'};
            }
        }
    }

    span {
        position: absolute;
        right: 30px;
        line-height: var(--text-sz-hint);
        font-size: var(--text-sz-hint);
    }
`;