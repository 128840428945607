import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { IConfig } from './types';

export const configApi = createApi({
    reducerPath: 'config',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        getConfig: build.query<IConfig, void>({
            query: () => ({
                url: `${Entity.config}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            })
        })
    }),
});