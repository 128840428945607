import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconScreenRotate } from 'assets/icons/screen-rotate.svg';
import { Component, Notification, Text } from './LandscapeScreen.styled';

export const LandscapeScreen = () => {
    const {t} = useTranslation();

    return (
        <Component>
            <Notification>
                <IconScreenRotate />
                <Text>
                    { t('note.screen_landscape_orientation') }
                </Text>
            </Notification>
        </Component>
    );
}