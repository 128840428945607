import React, { useState, FC, ReactNode } from 'react';
import { Component, Content } from './Modal.styled';

type UseModal = () => [IStateModal, (param:Omit<IStateModal, 'active'>) => void, () => void];

interface IAction {
    label: string
    handler: () => void
}

export interface IStateModal {
    active: boolean
    title?: string
    body?: string | ReactNode
    resolve?: IAction
    reject?: IAction
}

export const useModal:UseModal = () => {
    const [modal, setModal] = useState<IStateModal>({ title: '', body: '', active: false });

    const show = ({ title, body, resolve, reject }:Omit<IStateModal, 'active'>) => {
      setModal({ title, body, active: true, resolve, reject });
    }

    const hide = () => {
      setModal({ title: '', body: '', active: false });
    }

    return [modal, show, hide];
}

export const Modal:FC<IStateModal> = ({ active, title, body, reject, resolve }) => (
    <Component isActive={active}>
        <Content>
            { title && <h6>{ title }</h6> }
            { body && <p>{ body }</p> }
            <div>
                {
                    resolve &&
                    <button data-type="resolve" onClick={resolve.handler}>
                        { resolve.label }
                    </button>
                }
                {
                    reject &&
                    <button data-type="reject" onClick={reject.handler}>
                        { reject.label }
                    </button>
                }
            </div>
        </Content>
    </Component>
)

