import styled from 'styled-components/macro';

export type LoaderTypes = 'page' | 'fetch';

export const Component = styled.div<{ type:LoaderTypes }>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${({type}) => (type === 'page') && `
        width: 100%;
        height: 100%;
    `}

    ${({type}) => (type === 'fetch') && `
        padding: 10px 0;
    `}
    
    svg {
        width: 36px;
        height: 36px;
    }
`;
