import { publicPath, publicRoutes } from './public';
import { privatePath, privateRoutes } from './private';

export const Path = {
    public: publicPath,
    private: privatePath,
};

export const Routes = {
    public: publicRoutes,
    private: privateRoutes,
};