import React, { useEffect, useState } from 'react';
import Pages from 'pages';
import { useChat } from 'context/ChatContext';
import { useApp } from 'context/AppContext';
import { Header } from 'components/Header/Header';
import { Menu } from 'components/Menu/Menu';
import { LandscapeScreen } from 'components/LandscapeScreen/LandscapeScreen';
import { Component } from './App.styled';
import '@b3w/pp-portal/dist/portal-bundle.css';

const App = () => {
    const [isLandscapeOrientation, setLandscapeOrientation] = useState(false);
    const { events } = useChat();
    const { updateMessageCount } = useApp();

    window.addEventListener('orientationchange', (ev) => {
        if (window.screen.orientation.type === 'landscape-primary') {
            setLandscapeOrientation(true);
        } else {
            setLandscapeOrientation(false);
        }
    });

    useEffect(() => {
        if (events.length) {
            updateMessageCount();
        }
    }, [events]);

    return (
        <Component id="app">
            { isLandscapeOrientation && <LandscapeScreen /> }
            <Header/>
            <Menu/>
            <Pages/>
        </Component>
    )
};

export default App;
