import styled from 'styled-components/macro';

export const Component = styled.main`
    height: 100%;
    min-height: 100%;
    max-width: var(--page-sz-max-width);
    min-width: var(--page-sz-min-width);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    background: var(--page-bg-primary);
    color: var(--text-color-primary);
    user-select: none;
    overflow: hidden;
    z-index: 2;
`;
