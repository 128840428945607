import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';

export const pushAPI = createApi({
    reducerPath: 'push',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        postToken: build.mutation<void, any>({
            query: (payload) => ({
                url: `/push/save-token`,
                method: 'PUT',
                body: payload,
                headers: { ...getDefaultHeaders(true), ...getDynamicHeaders() },
            }),
        }),
    }),
});