import React, { FC } from 'react';
import { ReactComponent as IconPageLoading } from 'assets/icons/loader-page.svg';
import { ReactComponent as IconDataFetching } from 'assets/icons/loader-fetch.svg';
import { Component, LoaderTypes } from './Loader.styled';

interface IProps {
    type?: LoaderTypes
}

export const Loader:FC<IProps> = ({ type='page' }) => (
    <Component type={type}>
        { type === 'page' && <IconPageLoading/> }
        { type === 'fetch' && <IconDataFetching/> }
    </Component>
);
