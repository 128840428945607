export const findFromQuery = (key:string):string => {
    const pairs = window.location.search.slice(1).split('&');
    let result = '';

    pairs.forEach((item) => {
        const [prop, value] = item.split('=');
        if (prop.toLowerCase() === key.toLowerCase()) {
            result = value;
        }
    });

    return result;
};
