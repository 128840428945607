import styled from 'styled-components/macro';

export const Component = styled.div<{ isActive:boolean }>`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0; 
    right: 0;
    display: ${({ isActive }) => (isActive) ? 'block' : 'none'};
    background: var(--app-color-overflow);
    z-index: 999;
`;

export const Content = styled.div`
    max-width: 360px;
    min-width: 300px;
    padding: 20px;
    position: absolute;
    top: 50%; 
    left: 50%;
    background: var(--page-bg-primary);
    border-radius: 10px;
    transform:translate(-50%, -50%);
    z-index: 3;
    
    h6 {
        margin-bottom: 8px;
        font-size: var(--text-sz-subhead);
        text-align: center;
    }
    
    p {
        margin-bottom: 30px;
        text-align: center;
        font-size: var(--text-sz-body);
    }
    
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    
    small {
        line-height: 16px;
    }
    
    button {
        width: 160px;
        margin: 0 auto;
        padding: 12px;
        display: block;
        background: none;
        border-radius: 8px;
        font-family: inherit;
        font-size: var(--text-sz-body);
        outline: none;
        cursor: pointer;
    }
    
    button[data-type="resolve"] {
        background: var(--brand-color-1);
        border: 1px solid var(--brand-color-1);
        color: var(--text-color-light);
    }

    button[data-type="reject"] {
        background: var(--text-color-primary);
        border: 1px solid var(--text-color-primary);
        color: var(--text-color-light);
    }
    
    a {
        color: var(--brand-color-1);
        text-decoration: none;
    }
`;