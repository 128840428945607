import styled from 'styled-components/macro';

export const Component = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9999;
`;

export const Notification = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Text = styled.p`
    max-width: 250px;
    color: #333;
`;