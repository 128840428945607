import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { IPhotoList, IAddPhoto, IResult, ISetAvatar} from './types';

export const galleryAPI = createApi({
    reducerPath: 'gallery',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Gallery'],
    endpoints: (build) => ({
        getPhotos: build.query<IPhotoList, void>({
            query: () => ({
                url: `${Entity.profile}/gallery`,
                headers: { ...getDefaultHeaders() },
            }),
            providesTags: ['Gallery'],
        }),
        postPhoto: build.mutation<IResult, IAddPhoto>({
            query: (payload) => ({
                url: `${Entity.profile}/gallery`,
                method: 'POST',
                body: payload,
                headers: { ...getDynamicHeaders(), 'Accept': 'application/json' },
            }),
            invalidatesTags: ['Gallery'],
        }),
        setPhoto: build.mutation<IResult, ISetAvatar>({
            query: (payload) => ({
                url: `${Entity.profile}/gallery/set-avatar`,
                method: 'PUT',
                body: payload,
                headers: { ...getDefaultHeaders() },
            }),
            invalidatesTags: ['Gallery'],
        }),
        deletePhoto: build.mutation<IResult, string>({
            query: (payload) => ({
                url: `${Entity.profile}/gallery/${payload}`,
                method: 'DELETE',
                headers: { ...getDefaultHeaders() }
            }),
            invalidatesTags: ['Gallery'],
        }),
    }),
});