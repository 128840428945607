import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Routes as routes} from 'routes';
import { LP_STATUS_4, LP_STATUS_12 } from 'config/constants';
import { findFromQuery } from '../utils/findFromQuery';
import { useApp } from 'context/AppContext';
import { Error } from 'components/Error/Error';
import { Loader } from 'components/Loader/Loader';

const Pages = () => {
    const { isInitializing } = useApp();
    const status = findFromQuery('status');

    if (isInitializing) {
        return <Loader/>;
    }

    if (status && (status === LP_STATUS_4 || status === LP_STATUS_12)) {
        return <Error status={status}/>
    }

    return (
        <Suspense fallback={''}>
            <Routes>
                { routes.public.map((route, index) => <Route key={index} path={route.path} element={route.page}/>) }
                { routes.private.map((route, index) => <Route key={index} path={route.path} element={route.page}/>) }
            </Routes>
        </Suspense>
    );
}

export default Pages;