import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { ILogin, IResult, ISid } from './types';

export const authAPI = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        loginBySid: build.mutation<ILogin, ISid>({
            query: (payload) => ({
                url: `${Entity.auth}/by-sid`,
                method: 'POST',
                body: payload,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        logout: build.mutation<IResult, void>({
            query: () => ({
                url: `${Entity.auth}/logout`,
                method: 'POST',
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
    }),
});