import { createSlice, isRejectedWithValue, Middleware, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { ErrorStatus, IError } from 'api';

export const errorSlice = createSlice({
    name: 'error',
    initialState: { status: 0 as ErrorStatus, data: '' },
    reducers: {
        setError: (state, action:PayloadAction<IError>) => {
            state.status = action.payload.status;
            state.data = action.payload.data.message;
        },
    }
});

export const errorMiddleware:Middleware = (api:MiddlewareAPI) => (next) => (action) => {
    const { dispatch } = api;

    if (isRejectedWithValue(action)) {
        const { payload: { status, data } } = action;

        dispatch(errorSlice.actions.setError({ status, data }));
    }

    return next(action);
}