import { useEffect } from 'react';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { VAPID_KEY, FIREBASE_CONFIG } from 'config/constants';
import { pushAPI } from 'api';

export const useFirebase = () => {
    const [postToken, { data: postTokenData }] = pushAPI.usePostTokenMutation();

    useEffect(() => {
        isSupported()
            .then(async () => {
                const app = initializeApp(FIREBASE_CONFIG);
                const messaging = getMessaging(app);

                const fsw = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

                const token = await getToken(messaging, { vapidKey: VAPID_KEY, serviceWorkerRegistration: fsw });
                postToken({ token, device: 'web' });
            })
            .catch((e) => {
                console.log(e);
            })
    }, []);
}